import { varIs, varIsIn } from "common/var";
import { ReactComponent as HomeIcon } from "assets/menu-home.svg";
import { ReactComponent as StartedIcon } from "assets/menu-started.svg";
import { ReactComponent as AcademyIcon } from "assets/menu-academy.svg";
import { ReactComponent as ThreeFreeIcon } from "assets/menu-3free.svg";
import { ReactComponent as ScheduleIcon } from "assets/menu-live.svg";
import { ReactComponent as AlertIcon } from "assets/menu-alert.svg";
import { ReactComponent as TapIcon } from "assets/menu-tap.svg";
import { ReactComponent as ScannerIcon } from "assets/menu-scanner.svg";
import { ReactComponent as CalendarIcon } from "assets/menu-calendar.svg";
import { ReactComponent as CalculatorIcon } from "assets/menu-calculator.svg";
import { ReactComponent as ResourceIcon } from "assets/menu-resource.svg";
import { ReactComponent as NewsIcon } from "assets/menu-news.svg";
import { ReactComponent as LeaderboardIcon } from 'assets/menu-leaderboard.svg';
import { ReactComponent as PELeaderboardIcon } from 'assets/pe-leaderboard.svg';
import { ReactComponent as ReplayIcon } from 'assets/replay.svg';
import { FaTools } from "react-icons/fa";

export const getMenus = (myUser) => {
  let menus = [];
  let menu = null;
  let submenus = [];
  let submenu = null;
  if (
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
      "purged"
    ])
  ) {
    menu = {
      label: "Home",
      route: "/",
      icon: <HomeIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Getting Started",
      route: "/started",
      icon: <StartedIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Academy",
      route: "/academy",
      icon: <AcademyIcon />,
    };
    menus.push(menu);
    menu = {
      label: "3 & Free",
      route: "/three_free",
      icon: <ThreeFreeIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Live Sessions",
      route: "/schedule",
      icon: <ScheduleIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Session Replay",
      route: "/replays",
      icon: <ReplayIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Trade Alerts",
      route: "/alert",
      icon: <AlertIcon />,
    };
    // submenus = [];
    // submenu = {
    //   label: "All",
    //   route: "/alert/all",
    // };
    // submenus.push(submenu);
    // submenu = {
    //   label: "Forex",
    //   route: "/alert/forex",
    // };
    // submenus.push(submenu);
    // submenu = {
    //   label: "Crypto",
    //   route: "/alert/crypto",
    // };
    // submenus.push(submenu);
    // submenu = {
    //   label: "Binary Options",
    //   route: "/alert/binary_option",
    // };
    // submenus.push(submenu);
    // submenu = {
    //   label: "Commodities",
    //   route: "/alert/commodity",
    // };
    // submenus.push(submenu);
    // submenu = {
    //   label: "Indices",
    //   route: "/alert/index",
    // };
    // submenus.push(submenu);
    // menu['submenus'] = submenus;
    menus.push(menu);
    menu = {
      label: "Tap to Trade",
      route: "/tap",
      icon: <TapIcon />,
    };
    menus.push(menu);
    menu = {
      label: "Leaderboard",
      route: "/peleaderboard",
      icon: <PELeaderboardIcon />,
    };
    menus.push(menu);
    // if (myUser.username === 'demo') {
      menu = {
        label: "Tap Traders",
        route: "/tap-traders/trader",
        icon: <LeaderboardIcon />,
      };
      // submenus = [];
      // submenu = {
      //   label: "Strategies",
      //   route: "/leaderboard/strategy",
      // };
      // submenus.push(submenu);
      // submenu = {
      //   label: "Traders",
      //   route: "/leaderboard/trader",
      // };
      // submenus.push(submenu);
      // submenu = {
      //   label: "Followers",
      //   route: "/leaderboard/follower",
      // };
      // submenus.push(submenu);
      // menu['submenus'] = submenus;
      menus.push(menu);
    // }
    menu = {
      label: "Scanners",
      route: "/scanner",
      icon: <ScannerIcon />,
    };
    submenus = [];
    submenu = {
      label: "Golden Rain",
      route: "/scanner/golden_rain",
    };
    submenus.push(submenu);
    submenu = {
      label: "Go",
      route: "/scanner/pulse",
    };
    submenus.push(submenu);
    // submenu = {
    //   label: "Rhythm",
    //   route: "/scanner/rhythm",
    // };
    // submenus.push(submenu);
    submenu = {
      label: "Elite Sniper",
      route: "/scanner/elite_sniper",
    };
    submenus.push(submenu);
    submenu = {
      label: "Blitz",
      route: "/scanner/volatility_surge",
    };
    submenus.push(submenu);
    submenu = {
      label: "Breakout Momentum",
      route: "/scanner/breakout_momentum",
    };
    submenus.push(submenu);
    submenu = {
      label: "Pivot Point Reversal",
      route: "/scanner/pivot_point_reversal",
    };
    submenus.push(submenu);
    submenu = {
      label: "SmartCash",
      route: "/scanner/dynamic_equilibrium",
    };
    submenus.push(submenu);
    menu['submenus'] = submenus;
    menus.push(menu);
    menu = {
      label: "Resources",
      route: "/resource",
      icon: <ResourceIcon />
    };
    menus.push(menu);
    menu = {
      label: 'Tools',
      route: '/tool',
      icon: <FaTools />
    }
    submenus = [];
    submenu = {
      label: "Calendars",
      route: "/calendar",
    };
    submenus.push(submenu);
    submenu = {
      label: "Calculators",
      route: "/calculator",
    };
    submenus.push(submenu);
    submenu = {
      label: "Financial News",
      route: "/tool/financial_news",
    };
    submenus.push(submenu);
    submenu = {
      label: "Currency Strength Meter",
      route: "/tool/currency_strength",
    };
    submenus.push(submenu);
    submenu = {
      label: "Trend Analysis",
      route: "/tool/trend_analysis",
    };
    submenus.push(submenu);
    submenu = {
      label: "Glossary",
      route: "/tool/glossary",
    };
    submenus.push(submenu);
    menu['submenus'] = submenus;
    menus.push(menu);
  }

  return menus;
}

export const hasPageAccess = (pathname, myUser, sudoName) => {
  const menus = getMenus(myUser);
  let isAble = false;
  menus.forEach((el) => {
    if (el.route === pathname) {
      isAble = true;
    }
    if (el.submenus && el.submenus.length > 0) {
      el.submenus.forEach((el2) => {
        if (el2.route === pathname) {
          isAble = true;
        }
      });
    }
  });
  // --- external urls
  if (
    varIsIn("user.status", myUser.status, [
      "active",
      "inactive",
      "hold_temp",
    ]) &&
    (
      [
        "/academy/beginner",
        "/academy/intermediate",
        "/academy/advanced",
        "/academy/expert",
        "/academy/crypto",
        "/academy/educator",
      ].indexOf(pathname) >= 0 || 
      pathname.indexOf('/stream/') >= 0 ||
      pathname.indexOf('/replay/') >= 0
    )
  ) {
    isAble = true;
  }

  return isAble;
};

export const getPageTitle = (pathname, myUser) => {
  let pageTitle = null;
  getMenus(myUser).forEach((menu0) => {
    if (menu0.route === pathname) {
      pageTitle = menu0.label;
    }
    if (menu0.submenus) {
      menu0.submenus.forEach((submenu0) => {
        if (submenu0.route === pathname) {
          pageTitle = submenu0.label;
        }
      });
    }
  });

  if (pathname.indexOf('/alert/') >= 0) {
    pageTitle = 'Trade Alerts';
  } else if (pathname.indexOf('/tap/') >= 0) {
    pageTitle = 'Tap to Trade';
  } else if (pathname.indexOf('/academy/') >= 0) {
    pageTitle = 'Academy';
  } else if (pathname.indexOf('/stream/') >= 0) {
    pageTitle = 'Live Session';
  } else if (pathname.indexOf('/replay/') >= 0) {
    pageTitle = 'Session Replay';
  }

  return pageTitle;
};
